import React, {Component} from "react"
import SEO from "../components/seo"
import {Helmet} from "react-helmet";
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import "../styles/maps.scss"
import {Link} from "gatsby";

export class MapWrapper extends Component {

    render() {
        const mapStyle = [
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#333333"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dedede"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#c4c4c4"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e9e9e9"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e6e6e6"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e9e9e9"
                    },
                    {
                        "lightness": 17
                    }
                ]
            }
        ]
        return(
            <div>
                <Helmet defer={false}>
                    <meta charSet="utf-8" />
                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"/>
                    <link href="https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,900&amp;subset=latin-ext" rel="stylesheet"/>
                </Helmet>

                <div className="maps-main container-fluid pl-0 pr-0">

                    <SEO title="Map" keywords={[`S7 Design`, `Bla..`, `Bla....`]}/>

                    <div className="row h-100">
                        <div className="col-lg-6 col-12 text overflow-auto">
                            <div className="row justify-content-center">
                                <div className="col-10 mt-lg-5 mt-2 mb-lg-0 mb-3">
                                    <div className="back_btn">
                                        <Link to="/"><span className="icon-left-open-big"> </span>BACK TO HOME</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row h-75 align-content-center justify-content-center">
                                <div className="col-lg-8 col-12">
                                    <h1>Our office is located in</h1>
                                    <address>
                                        Narodnog Fronta 21<br/>
                                        Novi Sad<br/>
                                        Srbija
                                    </address>
                                    <p>Working hours:<br/>
                                    Monday-Friday: 9.00 h - 17.00 h</p>
                                    <p>Tel: +381 65 21 52 004</p>
                                    <p>Email: info@s7codedesign.com</p>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-10 col-12">
                                    <ul className="d-flex justify-content-center social-menu">
                                        {/*<li><a className="icon-instagram-filled" href="https://www.instagram.com/s7codedesign/" target='_blank' rel="noopener noreferrer"> </a></li>*/}
                                        {/*<li><a className="icon-linkedin-rect" href="https://www.linkedin.com/company/s7-design" target='_blank' rel="noopener noreferrer"> </a></li>*/}
                                        {/*<li><a className="icon-facebook-rect" href="https://www.facebook.com/s7codedesign/" target='_blank' rel="noopener noreferrer"> </a></li>*/}
                                        <li><a href="https://www.instagram.com/s7codedesign/" target='_blank' rel="noopener noreferrer">instagram</a></li>
                                        <li><a href="https://www.linkedin.com/company/s7-design" target='_blank' rel="noopener noreferrer">linkedIn</a></li>
                                        <li><a href="https://www.facebook.com/s7codedesign/" target='_blank' rel="noopener noreferrer">facebook</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 map">
                            <Map google={this.props.google}
                                 initialCenter={{
                                     lat: 45.240128,
                                     lng: 19.837682
                                 }}
                                 styles= {mapStyle}
                                 zoom={17}>
                                <Marker
                                    name={'S7'}
                                    position={{lat: 45.240128, lng: 19.837682}} />
                                <Marker />

                            </Map>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyA95T70W0b_4nrXndBQ4WPS4bQf_QwoHas')
})(MapWrapper)

